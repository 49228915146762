



































































































































































import { mapInfoContact, mapOutContact } from '@/helpers';
import { Contact, InputModel, Uploader, UploaderFile } from '@/models';
import { ContactService } from '@/services';
import { contextEmployeur } from '@/store';
import { IsNullOrEmpty, isValidState } from '@projetlucie/lc-front-sdk-old';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  methods: {
    isValidState
  }
})
export default class ContactCare extends Vue {
  private refs: any = this.$refs;
  private contactForm: Contact = {
    entreprise: null,
    prenom: null,
    nom: null,
    email: null,
    objet: null,
    message: null,
    telephone: null,
    policy: null
  };
  private objectsList: InputModel[] = [
    {
      id: 'contrat',
      name: 'Contrat'
    },
    {
      id: 'affiliation',
      name: 'Affiliation'
    },
    {
      id: 'coord_bancaire',
      name: 'Coordonnées bancaires'
    },
    {
      id: 'collaborateurs',
      name: 'Collaborateurs'
    },
    {
      id: 'probl_tech',
      name: 'Problème technique'
    },
    {
      id: 'autre',
      name: 'Autre'
    }
  ];
  private errorCorps: string | null = null;
  private files: UploaderFile[] = [];
  private modalState = false;

  mounted() {
    this.getData();
  }

  private async submit() {
    const valid = await this.refs.contactForm.validate();
    if (valid && this.contactForm.message) {
      await ContactService.contactCare(await mapOutContact(this.contactForm, this.files));
      this.setModalState(true);
      this.getData();
      this.refs.uploaderContact.reset();
      this.refs.contactForm.reset();
    } else {
      if (IsNullOrEmpty(this.contactForm.message)) {
        this.errorCorps = 'Veuillez écrire un message';
      }
    }
  }

  private getData() {
    if (this.employeur) this.contactForm = mapInfoContact(this.employeur);
  }

  private resetErrorCorps() {
    this.errorCorps = null;
  }

  private fieldIsDisabled(field: string | null) {
    return !IsNullOrEmpty(field);
  }

  private uploadPayload(payload: Uploader) {
    if (payload.successFiles) {
      this.files = payload.successFiles;
    }
  }

  private close() {
    window.close();
  }

  private setModalState(state: boolean) {
    this.modalState = state;
  }

  get employeur() {
    return contextEmployeur.getEmployeur;
  }
}
